<template>
  <div
    class="columns is-multiline has-padding-50 has-padding-bottom-400 is-marginless"
  >
    <div v-if="user.isDisabled" class="column is-12">
      <b-notification
        type="is-danger"
        :closable="false"
        class="has-padding-100 is-size-7"
      >
        <b-icon icon="user-slash" size="is-small" class="has-margin-right-25" />
        <strong>{{
          user.dateDisabled
            ? `This account was disabled ${$_.lowerFirst(
                $moment(user.dateDisabled.toDate()).calendar()
              )}.`
            : `This account has been disabled.`
        }}</strong>
        Whilst disabled this user will be unable to login or access their
        account, and they will not receive platform emails.
      </b-notification>
    </div>

    <div v-if="isReseller" class="column is-12">
      <b-notification :closable="false" type="is-dark" class="has-padding-100">
        <apart>
          <span>
            This user is a
            <strong>reseller</strong>.
          </span>
          <b-icon icon="user-tag" size="is-medium" />
        </apart>
      </b-notification>
    </div>

    <div v-if="isResellerUser" class="column is-12">
      <b-notification
        :closable="false"
        type="is-warning"
        class="has-padding-100"
      >
        <apart>
          <span>
            This user is a
            <strong>client of a reseller</strong> – avoid any references to
            <em>Fixed</em>.
          </span>
          <b-icon icon="user-friends" size="is-medium" />
        </apart>
      </b-notification>
    </div>

    <div v-if="isReseller" class="column is-12">
      <collapse title="Clients">
        <reseller-clients :reseller-id="userId" admin-context />
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="Tasks">
        <tasks-table
          :participant-id="userId"
          include-deleted
          allow-filtering
          admin-context
          @createTask="createTask"
        />
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="Notes">
        <user-notes :user-id="userId" admin-context />
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="Sites">
        <sites-table :user-id="userId" />
      </collapse>
    </div>

    <div v-if="!isResellerUser" class="column is-12">
      <collapse title="Contracts">
        <contracts-table-observed :user-id="userId" admin-context />
      </collapse>
    </div>

    <div v-if="!isResellerUser" class="column is-12">
      <collapse title="Invoices">
        <invoices-table :user-id="userId" />
      </collapse>
    </div>

    <div v-if="!isResellerUser" class="column is-12">
      <div class="columns is-multiline">
        <div class="column is-12 is-6-widescreen">
          <collapse title="Billing details">
            <currency-field :user-id="userId" />
            <billing-entity-field :user-id="userId" />
            <company-details-field :user-id="userId" />
          </collapse>
        </div>
        <div class="column is-12 is-6-widescreen">
          <collapse title="Payment methods">
            <account-credit :user-id="userId" :currency="user.currency" />
            <payment-methods :user-id="userId" context="view" />
          </collapse>
        </div>
      </div>
    </div>

    <div v-if="$_.get(user, 'affAccountRef')" class="column is-12">
      <collapse :title="`Affiliate account (ID: ${user.affAccountRef.id})`">
        <aff-account-provider :aff-account-ref="user.affAccountRef">
          <div>
            <aff-link-input class="has-margin-bottom-100" />
            <aff-account-stats-table />
            <aff-earnings-chart />
          </div>
        </aff-account-provider>
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="Activity logs">
        <logs-table :user-id="userId" admin-context />
      </collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminUser",
  components: {
    "user-notes": () => import("@shared/user/_userNotes"),
    "tasks-table": () => import("@shared/tasks/_tasksTable"),
    "logs-table": () => import("@shared/logs/_logsTable"),
    "sites-table": () => import("@shared/sites/_sitesTable"),
    "account-credit": () => import("@shared/account/_accountCredit"),
    "invoices-table": () => import("@shared/invoices/_invoicesTable"),
    "payment-methods": () => import("@shared/account/_paymentMethods"),
    "currency-field": () => import("@shared/account/_currencyField"),
    "billing-entity-field": () => import("@shared/account/_billingEntityField"),
    "company-details-field": () =>
      import("@shared/account/_companyDetailsField"),
    "contracts-table-observed": () =>
      import("@shared/contracts/_contractsTableObserved"),
    "reseller-clients": () => import("@shared/reseller/_clients"),
    "aff-account-provider": () =>
      import("@shared/affiliate/_affAccountProvider"),
    "aff-account-stats-table": () =>
      import("@shared/affiliate/_affAccountStatsTable"),
    "aff-link-input": () => import("@shared/affiliate/_affLinkInput"),
    "aff-earnings-chart": () =>
      import("@shared/affiliate/_earningLast30DaysChart")
  },
  data() {
    return {
      userId: this.$route.params.userId
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    isReseller() {
      return this.$store.getters["user/isReseller"](this.userId);
    },
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"](this.userId);
    }
  },
  created() {
    this.$store
      .dispatch("user/observeUser", { userId: this.userId })
      .catch(this.userNotFound);
  },
  beforeDestroy() {
    this.$store.dispatch("user/unobserveUser", { userId: this.userId });
  },
  methods: {
    createTask() {
      this.$store.dispatch("tasks/openAddTaskModal", {
        userId: this.userId
      });
    },
    userNotFound() {
      this.$router.push({ path: `/admin/users` });
      this.$toast.open({
        message: "User not found",
        position: "is-bottom",
        queue: false,
        type: "is-danger"
      });
    }
  }
};
</script>
